// implementations based off of http://pds25.egloos.com/pds/201504/21/98/RectangleBinPack.pdf
import type { Item } from './types'

const area = (item: Item) => item.height * item.width
const perimeter = (item: Item) => item.height * 2 + item.width * 2

function sides(item: Item) {
  return {
    short: Math.min(item.width, item.height),
    long: Math.max(item.width, item.height),
  }
}

export enum SortDirection {
  ASC,
  DESC,
}

abstract class Sorter {
  constructor(public direction: SortDirection) {}
  protected abstract comparer(a: Item, b: Item): number
  sort(items: Item[]) {
    const sortedItems = [...items].sort(this.comparer)

    return this.direction === SortDirection.DESC ? sortedItems.reverse() : sortedItems
  }
}

class AreaSorter extends Sorter {
  comparer(a: Item, b: Item) {
    return area(a) < area(b) ? -1 : 1
  }
}

class ShortSideSorter extends Sorter {
  comparer(a: Item, b: Item) {
    const aSides = sides(a)
    const bSides = sides(b)

    if (aSides.short === bSides.short)
      return aSides.long < bSides.long ? -1 : 1
    else
      return aSides.short < bSides.short ? -1 : 1
  }
}

class LongSideSorter extends Sorter {
  comparer(a: Item, b: Item) {
    const aSides = sides(a)
    const bSides = sides(b)
    if (aSides.long === bSides.long)
      return aSides.short < bSides.short ? -1 : 1
    else
      return aSides.long < bSides.long ? -1 : 1
  }
}

class PerimeterSorter extends Sorter {
  comparer(a: Item, b: Item) {
    return perimeter(a) < perimeter(b) ? -1 : 1
  }
}

class DifferencesSorter extends Sorter {
  comparer(a: Item, b: Item) {
    return Math.abs(a.width - a.height) < Math.abs(b.width - b.height) ? -1 : 1
  }
}

class RatioSorter extends Sorter {
  comparer(a: Item, b: Item) {
    return a.width / a.height < b.width / b.height ? -1 : 1
  }
}

export enum SortStrategy {
  Area,
  ShortSide,
  LongSide,
  Perimeter,
  Differences,
  Ratio,
}

export function GetSortImplementation(strategy: SortStrategy, direction: SortDirection): Sorter {
  let Impl
  switch (strategy) {
    case SortStrategy.Area:
      Impl = AreaSorter
      break
    case SortStrategy.Differences:
      Impl = DifferencesSorter
      break
    case SortStrategy.LongSide:
      Impl = LongSideSorter
      break
    case SortStrategy.Perimeter:
      Impl = PerimeterSorter
      break
    case SortStrategy.Ratio:
      Impl = RatioSorter
      break
    case SortStrategy.ShortSide:
      Impl = ShortSideSorter
      break
  }

  return new Impl(direction)
}
