import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import type { AxiosError } from 'axios'
import { useUtmStore } from '@/stores/utm'
import { useQuoteStore } from '@/stores/quote'
import { StateEnum } from '@apiTypes'
import { useAuthStore } from '@/stores/auth'
import { clearAuthData, isUserLoggedIn, refreshTokenHelper } from '@/auth/utils'
import { publicPages } from '@/router/public-pages'

type RouteGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => RouteGuardResponse
type RouteGuardResponse = Promise<any>

export const routeGuardMap: Record<string, RouteGuard> = {
  'free-quote': waypointsCheckGuard,
  'thanks': waypointsCheckGuard,
  'on-the-way': waypointsCheckGuard,
  'magic-link-hash': quoteByHashGuard,
  'login': loggedUserRedirectGuard,
  'register': loggedUserRedirectGuard,
  'index': landingDataGuard,
  'order-hash': orderGuard,
}

export function extendRouteWithGuard(route: RouteRecordRaw): RouteRecordRaw {
  const routeName = route.children?.[0]?.name?.toString() || ''
  const guards: RouteGuard[] = []

  if (!publicPages.includes(routeName))
    guards.push(dataGuard)

  if (routeGuardMap[routeName])
    guards.push(routeGuardMap[routeName])

  return { ...route, beforeEnter: guards }
}

async function dataGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  if (isUserLoggedIn()) {
    try {
      await fetchData()
      next()
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next, undefined, 'login')
    }
  }
  else {
    next({ name: 'login' })
  }
}

async function waypointsCheckGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const store = useQuoteStore()
  const utmStore = useUtmStore()

  if (!store?.quoteByID?.waypoints || store?.quoteByID?.waypoints.length < 2) { next('/') }
  else {
    if (store.quoteByID.uuid && to.path.includes('free-quote') && utmStore.utm.utm_source)
      await store.updateQuote({ utm_parameters: utmStore.utm })

    next()
  }
}

async function quoteByHashGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const hash = to.params.hash as string
  const store = useQuoteStore()

  if (isUserLoggedIn()) {
    try {
      await fetchData()
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next)
    }
  }

  try {
    const cachedQuote = store.getQuoteByHash

    if (cachedQuote?.hash === hash) {
      next()

      return
    }

    const quote = await store.fetchQuoteByHash(hash)

    if (quote && quote.state !== StateEnum.CANCELED) {
      if (quote.state === StateEnum.WITH_ORDER)
        next({ name: 'order-hash', params: { hash } })
      else
        next()
    }
    else { next({ path: '/', query: { canceled: 'true' } }) }
  }
  catch (e: unknown) {
    await checkGuardDataError(e as AxiosError, next, 'login')
  }
}

async function orderGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const hash = to.params.hash as string
  const store = useQuoteStore()

  try {
    const quote = await store.fetchQuoteWithOrder(hash)

    if (quote.state === StateEnum.WITH_ORDER) {
      next()

      return
    }

    if (quote.state === StateEnum.PRICEABLE || quote.state === StateEnum.CONFIRMED)
      next({ name: 'magic-link-hash', params: { hash } })
    else
      next('/')
  }
  catch (e: unknown) {
    next('/')
  }
}

export async function loggedUserRedirectGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const authStore = useAuthStore()

  if (isUserLoggedIn()) {
    try {
      await authStore.fetchCurrentUser()
      next({ name: 'index' })
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next, 'index')
    }
  }
  else {
    next()
  }
}

async function checkGuardDataError(error: AxiosError, next: NavigationGuardNext, redirectName?: string, unauthorizedRedirectName?: string) {
  if (error.response?.status === 401) {
    try {
      await refreshTokenHelper()
      await fetchData()
      redirectName ? next({ name: redirectName }) : next()
    }
    catch {
      // if there is error during token refresh we should redirect to login or register page based on user navigation
      clearAuthData()
      unauthorizedRedirectName ? next({ name: unauthorizedRedirectName }) : next()
    }
  }
  else {
    next({ name: 'error' })
  }
}

async function landingDataGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): RouteGuardResponse {
  const utmStore = useUtmStore()

  utmStore.storeUtmParameters(to.query)

  if (isUserLoggedIn()) {
    try {
      await fetchData()
      next()
    }
    catch (e: unknown) {
      await checkGuardDataError(e as AxiosError, next)
    }
  }
  else {
    next()
  }
}

async function fetchData() {
  const authStore = useAuthStore()
  if (!authStore.currentUser)
    await authStore.fetchCurrentUser()
}
