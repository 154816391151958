import { createI18n } from 'vue-i18n'

const messages = Object.fromEntries(
  Object.entries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import.meta.glob<{ default: any }>('./locales/*.json', { eager: true }))
    .map(([key, value]) => [key.slice(10, -5), value.default]),
)

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard', minimumFractionDigits: 0, maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
  'sk-SK': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent', useGrouping: false,
    },
  },
}

export default createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,

  // @ts-expect-error: Some missmatch in types, it should be fine
  numberFormats,
  fallbackWarn: false,
  missingWarn: false,
})
