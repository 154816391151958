export const publicPages = [
  'index',
  'login',
  'error',
  'register',
  'password-reset',
  'password-reset-confirm-uid-token',
  'account-confirm-email-hash',
  'free-quote',
  'magic-link-hash',
  'order-hash',
  'thanks',
  'on-the-way',
]
