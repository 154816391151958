/* eslint-disable import/order */
import App from '@/App.vue'
import { tooltip } from '@/directives/tooltip'
import i18n from '@/plugins/i18n'
import { ToastPlugin } from '@/plugins/toast'
import router from '@/router'
import '@/assets/styles/styles.scss'
import 'vue-final-modal/style.css'
import 'bootstrap'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import { createGmapVuePlugin } from '@gmap-vue/v3'
import '@gmap-vue/v3/dist/style.css'
import { createGtm } from '@gtm-support/vue-gtm'

// Create vue app
const app = createApp(App)

// Use plugins
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(createVfm())
app.use(ToastPlugin)
app.use(createGmapVuePlugin({
  load: {
    key: import.meta.env.VITE_GOOGLE_API_KEY || '',
    libraries: 'places,geometry',
    region: 'EU',
  },
}))

if (import.meta.env.MODE === 'production') {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID || 'GTM-UNDEFINED',
      vueRouter: router,
      debug: true,
    }),
  )
}
app.directive('tooltip', tooltip)

app.mount('#app')
