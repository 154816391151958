import { ref } from 'vue'
import { useFeedbackStore } from '@store/feedback'
import type { Feedback } from '@apiTypes'

export function useFeedback(): { loading: Ref<boolean>; error: Ref<string>; createFeedback: (payload: Partial<Feedback>) => Promise<void>; feedback: Ref<Feedback | undefined> } {
  const loading = ref(false)
  const error = ref('')
  const feedbackStore = useFeedbackStore()
  const feedback = ref<Feedback>(feedbackStore.getFeedback())

  const createFeedback = async (payload: Partial<Feedback>) => {
    loading.value = true
    try {
      await feedbackStore.createFeedback(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  return { loading, error, createFeedback, feedback }
}
