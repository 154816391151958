

import __layout_0 from '/app/src/layouts/default.vue'
export const layouts = {
'404': () => import('/app/src/layouts/404.vue'),
'blank': () => import('/app/src/layouts/blank.vue'),
'default': __layout_0,
'landing': () => import('/app/src/layouts/landing.vue'),
'components/BlogRedirect': () => import('/app/src/layouts/components/BlogRedirect.vue'),
'components/Footer': () => import('/app/src/layouts/components/Footer.vue'),
'components/FreeQuoteHeader': () => import('/app/src/layouts/components/FreeQuoteHeader.vue'),
'components/Header': () => import('/app/src/layouts/components/Header.vue'),
'components/HowItWorksView': () => import('/app/src/layouts/components/HowItWorksView.vue'),
'components/Service': () => import('/app/src/layouts/components/Service.vue'),
'components/ServicesBenefitsView': () => import('/app/src/layouts/components/ServicesBenefitsView.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
