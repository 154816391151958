<template>
  <div id="blog" class="row justify-content-between p-md-5 p-3">
    <div class="card rounded-5 p-md-4 shadow-lg border border-light">
      <div class="card-body py-4">
        <div class="row">
          <div class="col-lg-1 col-12 d-flex align-items-center justify-content-center">
            <img src="@images/ic_blog.svg" alt="blog icon">
          </div>
          <div class="col-lg-7 col-12 d-flex align-items-center">
            <div class="ps-2">
              <h2 class="fw-bolder mb-0">
                {{ $t('Latest news, guides and posts') }}
              </h2>
              <span class="text-muted">{{ $t('Stay updated with the latest insights and tips from our transport and technology experts.') }}</span>
            </div>
          </div>
          <div class="col-lg-4 col-12 d-flex align-items-center justify-content-center">
            <a
              class="btn btn-primary m-3 px-4 py-3 rounded-3" href="https://blog.instacarrier.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="me-2" height="11"
                src="@images/ic_arrow_outward.svg"
                alt="arrow icon"
              >
              {{ $t('Go to InstaCarrier Blog') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
