import { parsePhoneNumber } from 'awesome-phonenumber'
import { string } from 'yup'
import i18n from '@/plugins/i18n'

export const phoneNumberValidator = string().test('is-valid-phone', 'Invalid phone number, must include country prefix', value => {
  if (!value)
    return true

  const phoneNumber = parsePhoneNumber(value)

  return phoneNumber && phoneNumber.valid && phoneNumber.number.e164 === value
})

export const emailValidator = string()
  .test('is-valid-email', 'Invalid email format', value => {
    if (!value)
      return false

    const [local, domain] = value.split('@')

    if (!domain || !domain.includes('.'))
      return false

    const [name, extension] = domain.split('.')

    return !!(name && extension)
  })

export function isCompanyRequiredValidator(isCompany: boolean | undefined) {
  const { t } = i18n.global

  return string().when('is_company', {
    is: isCompany,
    then: schema => schema
      .required(t('This field is required')),
    otherwise: schema => schema
      .nullable().optional(),
  })
}
