import type { UpdateBillingInfo, Waypoint } from '@apiTypes'
import type { Options } from '@store/options'

export function formatCity<T extends { postcode?: string; city?: string; country?: string }>(address: T | undefined, countries: Options): string {
  const part1 = [address?.postcode, address?.city].filter(Boolean).join(' ')
  const country = countries[address?.country || '']

  return [part1, country].filter(Boolean).join(', ')
}

export function formatAddress(address: Waypoint | UpdateBillingInfo | undefined): string {
  return [
    address?.postcode || address?.postal_code,
    address?.name,
    address?.street,
    address?.street_number || address?.home,
    address?.city,
    address?.country,
  ].filter(Boolean).join(', ')
}

export function formatWaypointAddress<T extends { name?: string; street?: string; street_number?: string }>(address: T | undefined, formattedCity: string): (string | undefined)[] {
  function formatStreet(addressPart: T | undefined) {
    if (addressPart?.street && addressPart?.street_number)
      return `${addressPart?.street}, ${addressPart?.street_number}`

    return address?.street
  }

  return [
    address?.name,
    formatStreet(address),
    formattedCity,
  ].filter(Boolean)
}
