import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Feedback } from '@apiTypes'
import api from '@api'

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    feedback: {} as Feedback,
  }),
  getters: {
    getFeedback: state => {
      return () => state.feedback
    },
  },
  actions: {
    async createFeedback(payload: Partial<Feedback>) {
      const response = await api.feedbacksCreate(<Feedback>payload)
      if (response)
        this.feedback = response.data
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useFeedbackStore, import.meta.hot))
