<script setup lang="ts">
import { useField } from 'vee-validate'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  toggle: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  htmlLabel: {
    type: String,
    default: '',
  },
  validation: {
    type: Boolean,
    default: false,
  },
  isChecked: {
    type: Boolean || undefined,
    default: undefined,
  },
})

const emit = defineEmits<{
  (e: 'onChange', checked: boolean): void
}>()

const name = toRef(props, 'name')

const {
  checked,
  errorMessage,
  handleChange,
  setValue: setFieldValue,
} = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: true,
  uncheckedValue: false,
})

const setValue = (value: boolean) => setFieldValue(value)

defineExpose({
  setValue,
})

function onChange(newVal: boolean) {
  handleChange(newVal)
  emit('onChange', newVal)
}
</script>

<template>
  <div :class="{ 'validation-input': validation, 'has-error': !!errorMessage }">
    <div class="form-check" :class="{ 'form-switch': toggle }">
      <label class="form-check-label">
        <input
          :name="name" :checked="typeof isChecked === 'undefined' ? checked : isChecked"
          class="form-check-input border border-primary" :disabled="disabled"
          type="checkbox" @change="(event) => onChange(event.target?.checked)"
        >
        <span v-if="htmlLabel" v-html="htmlLabel" />
        <span v-else>{{ label }}</span>
      </label>
    </div>

    <ValidationMessage v-if="validation" :error-message="errorMessage" />
  </div>
</template>

<style scoped lang="scss">
.user-select_help {
  cursor: help;
}
</style>
