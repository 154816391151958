<template>
  <div id="how-it-works" class="row mt-5 pt-lg-5 justify-content-center">
    <div class="col-auto text-center">
      <h1 class="fw-bolder">
        {{ $t('How it works') }}
      </h1>
      <p class="fs-5 text-muted">
        {{ $t('It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.') }}
      </p>
    </div>
  </div>
  <div class="row justify-content-center pt-lg-5 pt-4">
    <div class="col-md">
      <div class="row">
        <div class="col-auto ms-lg-auto">
          <strong class="d-block text-primary mb-1">01.</strong>
          <h4 class="mb-3">
            {{ $t('Instant quote and order') }}
          </h4>
          <ul class="fancy-list">
            <li>{{ $t('Send a transportation request') }}</li>
            <li>{{ $t('Get the quote') }}</li>
            <li>{{ $t('Book transport') }}</li>
            <li>{{ $t('Transport confirmed') }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-auto text-center">
      <div class="mx-5 py-md-5">
        <img class="mt-5 mb-3" src="@images/ic_clock_blue.svg" alt="clock">
        <div class="dashed-arrow mb-3">
          <span class="line" />
          <span class="arrow left" />
          <span class="arrow right" />
        </div>
        <p class="text-primary fw-bolder text-uppercase">
          {{ $t('Just in 2 hours') }}
        </p>
      </div>
    </div>
    <div class="col-md col-sm-auto">
      <strong class="d-block text-primary mb-1">02.</strong>
      <h4 class="mb-3">
        {{ $t('Enjoy delivery and online dashboard/tracking') }}
      </h4>
      <ul class="fancy-list">
        <li>{{ $t('Truck\'s licence plate info') }}</li>
        <li>{{ $t('Pickup confirmed') }}</li>
        <li>{{ $t('Delivery tracking') }}</li>
        <li>{{ $t('Delivery confirmed') }}</li>
        <li>{{ $t('Payment') }}</li>
      </ul>
    </div>
  </div>
  <div class="row justify-content-center py-4">
    <div class="col-lg-5 text-center">
      <a class="btn btn-primary m-3 px-4 py-3" href="#">
        {{ $t('Get a FREE quote now') }}
      </a>
      <p class="text-muted mb-0">
        {{ $t('Get the instant transport price.') }}
      </p>
      <h4>
        {{ $t('Get your price in 3 minutes. Pay Later!') }}
      </h4>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/abstracts/colors';

.fancy-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fancy-list li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 15px;
}

.fancy-list li:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 13px;
  top: 35%;
  bottom: -15px;
  width: 1px;
  background-color: $primary;
}

.fancy-list li::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 35%;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  background-color: $primary;
  border-radius: 50%;
}

.dashed-arrow {
  display: flex;
  align-items: center;
  position: relative;
}

.line {
  border-bottom: 0.2rem dashed $primary;
  flex-grow: 1;
  height: 0;

}

.arrow {
  width: 0.5rem;
  height: 0.5rem;
  border-right: 0.2rem solid $primary;
  position: absolute;
  transform-origin: center;
}

.left {
  left: 100%;
  transform: translateX(-50%) translateY(-0.05rem) rotate(45deg);
}

.right {
  left: 100%;
  transform: translateX(-50%) translateY(-0.05rem) rotate(-45deg);
}
</style>
