import { ref } from 'vue'
import { usePostalCodes } from '@/composables/postal-codes'
import { isLTLAllowed } from '@/utils/waypoint.utils'
import { useGoods } from '@/composables/goods'
import type { Vehicle } from '@/utils/vehicle'
import { BinPacking2D, vehicles } from '@/utils/vehicle'
import { useQuoteStore } from '@/stores/quote'
import type { Good, QuoteByHash, QuoteByID, UpdateUserBillingType, Waypoint } from '@apiTypes'

export function useQuote(): {
  isFTL: (currentTotalWeight: number, goods: Good[], vehicle: Vehicle, tailLift: boolean) => boolean
  quoteByHash: ComputedRef<QuoteByHash>
  loading: Ref<boolean>
  error: Ref<string>
  quoteByID: ComputedRef<QuoteByID>
  updateQuote: (payload: Partial<QuoteByID>) => Promise<void>
  updateQuoteByHash: (payload: Partial<QuoteByHash>) => Promise<void>
  createQuote: (payload: Partial<QuoteByID>) => Promise<void>
  updateWaypoint: (updatedWaypoint: Waypoint, index: number, toast: any) => void
  updateQuoteUserType: (hash: string | undefined, payload: Partial<UpdateUserBillingType>) => Promise<void> } {
  const loading = ref(false)
  const error = ref('')
  const quoteStore = useQuoteStore()
  const { totalWeight } = useGoods()
  const quoteByID = computed(() => quoteStore.quoteByID)
  const quoteByHash = computed(() => quoteStore.quoteByHash)
  const { postalCodes } = usePostalCodes()

  const createQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      await quoteStore.createQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const isFTL = (currentTotalWeight: number, goods: Good[], vehicle: Vehicle, tailLift: boolean) => {
    const binPacking = new BinPacking2D(vehicle, goods)
    const fillPercentage = binPacking.fitPackages().fillPercentage
    const halfSpaceOrMoreOccupied = fillPercentage >= 50
    const waypoints = quoteByID.value.waypoints || []

    const isLTL = isLTLAllowed(waypoints, currentTotalWeight, tailLift, postalCodes.value.ltl_countries)

    return currentTotalWeight > 500 || halfSpaceOrMoreOccupied || !isLTL
  }

  const updateQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      const currentTotalWeight = totalWeight.value || 0

      if (isFTL(currentTotalWeight, payload.goods || [], vehicles[0], payload.tail_lift || false))
        payload.ftl = true

      await quoteStore.updateQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateQuoteByHash = async (payload: Partial<QuoteByHash>) => {
    loading.value = true
    try {
      await quoteStore.updateQuoteByHash(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateWaypoint = async (updatedWaypoint: Waypoint, index: number, toast: any) => {
    loading.value = true
    try {
      await quoteStore.updateWaypoint(updatedWaypoint, index)
      error.value = ''
    }
    catch (e: any) {
      const status = e.response.status
      const data = e.response.data
      if ((status === 404 || status === 400) && data.error)
        toast.error(data.error)
    }
    finally { loading.value = false }
  }

  const updateQuoteUserType = async (hash: string | undefined, payload: Partial<UpdateUserBillingType>) => {
    loading.value = true
    try {
      if (hash)
        await quoteStore.updateQuoteUserType(hash, payload)

      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  return { loading, error, createQuote, updateQuote, updateQuoteByHash, quoteByID, quoteByHash, isFTL, updateWaypoint, updateQuoteUserType }
}
