<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { logoutHelper } from '@/auth/utils'
import Dropdown from '@/components/Dropdown.vue'
import { useAuthStore } from '@/stores/auth'

defineProps({
  freeQuote: {
    type: Boolean,
    required: false,
  },
})

const authStore = useAuthStore()
const currentUserName = computed(() => authStore.currentUser?.name || '')
const currentUserRole = computed(() => authStore.currentUser?.is_staff)
</script>

<template>
  <Dropdown
    v-if="currentUserName"
    :title="currentUserName || $t('User Menu')" :level="0"
  >
    <template #default>
      <a
        class="dropdown-toggle custom-dropdown-toggle btn btn-light-blue ms-lg-2 mt-lg-0 mt-2 h-100"
        :class="{ 'bg-white ms-0': freeQuote }"
        role="button"
      >
        <div class="d-flex align-items-center h-100 w-100">
          <i class="fa-solid fa-user text-primary" :class="{ 'text-danger': currentUserRole }" />
          <span class="ps-1 text-primary" :class="{ 'text-danger': currentUserRole }">{{ currentUserName }}</span>
        </div>
      </a>
    </template>

    <template #content>
      <ul class="list-unstyled m-0 p-0">
        <li>
          <RouterLink class="dropdown-item d-flex gap-2 align-items-center" :to="{ path: '/account', query: { form: 'general' } }">
            <i class="fa-solid fa-user" /> {{ $t('Account settings') }}
          </RouterLink>
        </li>
        <li class="dropdown-divider" />
        <li>
          <RouterLink class="dropdown-item d-flex gap-2 align-items-center" :to="{ path: '/account', query: { form: 'password' } }">
            <img src="@images/ic_lock.svg" alt="lock"> {{ $t('Change password') }}
          </RouterLink>
        </li>
        <li class="dropdown-divider" />
        <li>
          <a class="dropdown-item d-flex gap-2 align-items-center" href="#" @click.prevent="logoutHelper">
            <img src="@images/ic_move_item.svg" alt="move item"> {{ $t('Sign Out') }}
          </a>
        </li>
      </ul>
    </template>
  </Dropdown>
  <RouterLink
    v-else to="/login"
    class="btn btn-light-blue ms-lg-1 mt-lg-0 mt-2 h-100 d-flex align-items-center"
    :class="{ 'bg-white': freeQuote }"
  >
    <div class="d-flex align-items-center h-100 w-100">
      <i class="fa-solid fa-user text-primary" /> <span class="ps-1 text-primary">{{ $t('Sign In') }}</span>
    </div>
  </RouterLink>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/colors';
a.btn-light-blue {
  background-color: $primary-light;
}
.custom-dropdown-toggle::after {
  display: none !important;
}
.custom-dropdown-toggle {
  margin-left: 1.75rem;
}
</style>
