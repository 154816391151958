import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from '~pages'
import { isUserLoggedIn } from '@/auth/utils'
import { extendRouteWithGuard } from '@/router/guards'
import { publicPages } from '@/router/public-pages'

const routes = [
  { path: '/home', redirect: '/' },
  ...setupLayouts(generatedRoutes),
].map(route => extendRouteWithGuard(route))

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(to => {
  const authRequired = !publicPages.reduce((a, b) => a || RegExp(b).test(<string>to.name), false)
  if (authRequired && !isUserLoggedIn())
    return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
})

export default router
