import Toast, { POSITION, useToast } from 'vue-toastification'
import type { App, Plugin } from 'vue'
import 'vue-toastification/dist/index.css'

export const ToastPlugin: Plugin = {
  install(app: App) {
    app.use(Toast, {
      position: POSITION.TOP_CENTER,
      transition: 'Vue-Toastification__fade',
    })

    app.config.globalProperties.$toast = useToast()
  },
}
