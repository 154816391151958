import type { Item, Rectangle } from './types'
import type { SelectionStrategy } from './selection-strategies'
import { GetSelectionImplementation } from './selection-strategies'
import type { SortDirection, SortStrategy } from './sort-strategies'
import { GetSortImplementation } from './sort-strategies'
import type { SplitStrategy } from './split-strategies'
import { GetSplitImplementation } from './split-strategies'

export interface PackStrategyOptions {
  binHeight: number
  binWidth: number
  items: Item[]
  selectionStrategy: SelectionStrategy
  splitStrategy: SplitStrategy
  sortStrategy: SortStrategy
  sortOrder: SortDirection
  kerfSize: number
  allowRotation: boolean
}

export interface PackedItem {
  item: any
  width: number
  height: number
  x: number
  y: number
  bin: number
}

export function PackStrategy({
  binHeight,
  binWidth,
  items,
  selectionStrategy,
  splitStrategy,
  sortStrategy,
  sortOrder,
  kerfSize,
  allowRotation,
}: PackStrategyOptions) {
  let binCount = 0
  const freeRectangles: Rectangle[] = []

  const createBin = () => {
    binCount++
    freeRectangles.push({
      width: binWidth,
      height: binHeight,
      x: 0,
      y: 0,
      bin: binCount,
      id: 'root',
    })
  }

  const splitter = GetSplitImplementation(splitStrategy, kerfSize)
  const selector = GetSelectionImplementation(selectionStrategy)
  const sorter = GetSortImplementation(sortStrategy, sortOrder)

  const sortedItems = sorter.sort(items)

  const rotateItem = (item: Item) => {
    return { ...item, height: item.width, width: item.height }
  }

  const splitRectangle = ({ rectangle, item }: { rectangle: Rectangle; item: Item }) => {
    return splitter.split(rectangle, item).filter(r => r.width > 0 && r.height > 0)
  }

  const getSelectionOption = (item: Item) => {
    const rectangle = selector.select(freeRectangles, item)
    if (!rectangle)
      return null

    const splitRectangles = splitRectangle({ rectangle, item })

    return {
      rectangle,
      splitRectangles,
      item,
    }
  }

  const selectRectangleOption = (item: Item) => {
    const originalOption = getSelectionOption(item)
    let rotatedOption = null
    let rotatedItem
    if (allowRotation) {
      rotatedItem = rotateItem(item)
      rotatedOption = getSelectionOption(rotatedItem)
    }
    if (originalOption === null && rotatedOption === null) {
      return null
    }
    else if (originalOption === null) {
      return rotatedOption
    }
    else if (rotatedOption === null) {
      return originalOption
    }
    else {
      const getBiggestSplitRectangle = ({ splitRectangles }: { splitRectangles: Rectangle[] }) =>
        Math.max(...splitRectangles.map(split => split.height * split.width))

      const originalMax = getBiggestSplitRectangle(originalOption)
      const rotatedMax = getBiggestSplitRectangle(rotatedOption)
      if (getBiggestSplitRectangle(originalOption) >= getBiggestSplitRectangle(rotatedOption))
        return originalOption
      else
        return rotatedOption
    }
  }

  const packedItems = sortedItems
    .map((item, idx) => {
      let selectedOption = selectRectangleOption(item)
      if (!selectedOption) {
        createBin()
        selectedOption = selectRectangleOption(item)
      }
      if (!selectedOption) {
        throw new Error(
          `item at index ${idx} with dimensions ${item.width}x${item.height} exceeds bin dimensions of ${binWidth}x${binHeight}`,
        )
      }
      const { rectangle, splitRectangles } = selectedOption
      const { width, height, ...otherItemProps } = selectedOption.item

      const packedItem = {
        item: otherItemProps,
        width,
        height,
        x: rectangle.x,
        y: rectangle.y,
        bin: rectangle.bin,
      }

      const rectIndex = freeRectangles.findIndex(r => r === rectangle)

      freeRectangles.splice(rectIndex, 1, ...splitRectangles)

      return packedItem
    })
    .reduce((bins, item) => {
      if (bins.length >= item.bin)
        bins[item.bin - 1].push(item)
      else
        bins.push([item])

      return bins
    }, [] as PackedItem[][])

  return {
    sortStrategy,
    sortOrder,
    packedItems,
    splitStrategy,
    selectionStrategy,
  }
}
