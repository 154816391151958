import { SplitStrategy } from './split-strategies'
import { SelectionStrategy } from './selection-strategies'
import { SortDirection, SortStrategy } from './sort-strategies'
import type { PackedItem } from './pack-strategy'
import { PackStrategy } from './pack-strategy'
import type { Item } from './types'
import { cartesian } from './util'

interface PackerInputs { binHeight: number; binWidth: number; items: Item[] }
interface PackerConfig {
  selectionStrategy?: SelectionStrategy
  splitStrategy?: SplitStrategy
  sortStrategy?: SortStrategy
  kerfSize?: number
  allowRotation?: boolean
}

type PackerResult = PackedItem[][] | null

function Packer(
  { binHeight, binWidth, items }: PackerInputs,
  {
    selectionStrategy,
    splitStrategy,
    sortStrategy,
    kerfSize = 0,
    allowRotation = true,
  }: PackerConfig = {},
) {
  function enumToArray<T>(enumVariable: T) {
    return Object.values(enumVariable)
      .filter(value => Number.parseInt(value, 10) >= 0)
      .map(value => value as keyof T)
  }

  const selectionStrategies
    = selectionStrategy !== undefined ? [selectionStrategy] : enumToArray(SelectionStrategy)

  const splitStrategies = splitStrategy !== undefined ? [splitStrategy] : enumToArray(SplitStrategy)
  const sortStrategies = sortStrategy !== undefined ? [sortStrategy] : enumToArray(SortStrategy)

  const allStrategies = cartesian(selectionStrategies, splitStrategies, sortStrategies, [
    SortDirection.ASC,
    SortDirection.DESC,
  ])

  return allStrategies
    .map(([selStrategy, splStrategy, srtStrategy, sortOrder]) =>
      PackStrategy({
        binWidth,
        binHeight,
        items,
        splitStrategy: splStrategy,
        selectionStrategy: selStrategy,
        sortStrategy: srtStrategy,
        sortOrder,
        kerfSize,
        allowRotation,
      }),
    )
    .reduce((bestCompressed, packResult) => {
      const { packedItems } = packResult
      if (!bestCompressed || packedItems.length < bestCompressed.length)
        return packedItems
      else
        return bestCompressed
    }, null as PackerResult)
}

export { SplitStrategy, SelectionStrategy, SortStrategy, Packer as packer }
