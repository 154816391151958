const __pages_import_0__ = () => import("/src/pages/thanks/index_old.vue");
const __pages_import_1__ = () => import("/src/pages/thanks/index.vue");
const __pages_import_2__ = () => import("/src/pages/on-the-way/index.vue");
const __pages_import_3__ = () => import("/src/pages/free-quote/index.vue");
const __pages_import_4__ = () => import("/src/pages/account/index.vue");
const __pages_import_5__ = () => import("/src/pages/register.vue");
const __pages_import_6__ = () => import("/src/pages/login.vue");
import __pages_import_7__ from "/src/pages/index.vue";
const __pages_import_8__ = () => import("/src/pages/[...all].vue");
const __pages_import_9__ = () => import("/src/pages/account-confirm-email/[hash].vue");
const __pages_import_10__ = () => import("/src/pages/magic-link/[hash].vue");
const __pages_import_11__ = () => import("/src/pages/order/[hash].vue");
const __pages_import_12__ = () => import("/src/pages/password-reset/confirm/[uid]/[token].vue");

const routes = [{"name":"thanks-index_old","path":"/thanks/index_old","component":__pages_import_0__,"props":true},{"name":"thanks","path":"/thanks","component":__pages_import_1__,"props":true,"meta":{"layout":"blank"}},{"name":"on-the-way","path":"/on-the-way","component":__pages_import_2__,"props":true},{"name":"free-quote","path":"/free-quote","component":__pages_import_3__,"props":true},{"name":"account","path":"/account","component":__pages_import_4__,"props":true,"meta":{"layout":"landing"}},{"name":"register","path":"/register","component":__pages_import_5__,"props":true,"meta":{"layout":"landing"}},{"name":"login","path":"/login","component":__pages_import_6__,"props":true,"meta":{"layout":"landing"}},{"name":"index","path":"/","component":__pages_import_7__,"props":true,"meta":{"layout":"blank"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_8__,"props":true,"meta":{"layout":404}},{"name":"account-confirm-email-hash","path":"/account-confirm-email/:hash","component":__pages_import_9__,"props":true,"meta":{"layout":"landing"}},{"name":"magic-link-hash","path":"/magic-link/:hash","component":__pages_import_10__,"props":true},{"name":"order-hash","path":"/order/:hash","component":__pages_import_11__,"props":true},{"name":"password-reset-confirm-uid-token","path":"/password-reset/confirm/:uid/:token","component":__pages_import_12__,"props":true,"meta":{"layout":"landing"}}];

export default routes;