import { PackageTypeEnum } from '@apiTypes'

export interface Package {
  package_type: PackageTypeEnum
  length: number | undefined
  width: number | undefined
  height: number | undefined
  count: number | undefined
  unit_weight: number | undefined
  stackable: boolean | undefined
  disabledFields: Array<string> | undefined
}

export const packages: Package[] = [
  {
    package_type: PackageTypeEnum.EntireVAN,
    length: 420,
    width: 200,
    height: 200,
    count: 1,
    unit_weight: 1000,
    stackable: false,
    disabledFields: ['length', 'width', 'height', 'unit_weight'],
  },
  {
    package_type: PackageTypeEnum.EuroPallet,
    length: 120,
    width: 80,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.IndustrialPallet,
    length: 120,
    width: 100,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.EuroGitterbox,
    length: 123,
    width: 84,
    height: 97,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.IBCContainer,
    length: 120,
    width: 100,
    height: 116,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    // todo change 1 to undefined and fix it in packaging algo
    package_type: PackageTypeEnum.Custom,
    length: 1,
    width: 1,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: [],
  },
]
