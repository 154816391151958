import { Tooltip } from 'bootstrap'

export const tooltip = {

  mounted(el: HTMLElement) {
    el.setAttribute('data-bs-toggle', 'tooltip')

    createTooltip(el)
  },

  beforeUnmount(el: HTMLElement) {
    destroyTooltip(el)
  },

  onUnmounted(el: HTMLElement) {
    destroyTooltip(el)
  },

}

function createTooltip(el: HTMLElement) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const bootstrapTooltip = new Tooltip(el)
}

function destroyTooltip(el: HTMLElement) {
  const bootstrapTooltip = Tooltip.getInstance(el)

  bootstrapTooltip?.dispose()
}
