import { AllowedEnum } from '@apiTypes'
import type { CountryEnum, LTLCountry, Waypoint } from '@apiTypes'

export function isLTLAllowed(waypoints: Waypoint[], currentTotalWeight: number, tailLift: boolean | undefined, ltlCountries: LTLCountry[]) {
  if (waypoints) {
    const pickupCountry = waypoints[0].country || undefined
    const pickupPostcode = waypoints[0].postcode || undefined
    const deliveryCountry = waypoints[1].country || undefined
    const deliveryPostcode = waypoints[1].postcode || undefined

    const checkingPickupResult = checkCountry(pickupCountry, pickupPostcode, ltlCountries)
    const checkingDeliveryResult = checkCountry(deliveryCountry, deliveryPostcode, ltlCountries)

    return checkLTL(checkingDeliveryResult, currentTotalWeight, tailLift) && checkLTL(checkingPickupResult, currentTotalWeight, tailLift)
  }
}

function checkCountry(country: CountryEnum | undefined, postcode: string | undefined, ltlCountries: LTLCountry[]) {
  if (country && !postcode) {
    const ltlCountry = ltlCountries.find(code => code.country === country)

    if (!ltlCountry)
      return {}

    return {
      whole_disallowed: ltlCountry.whole_disallowed,
      whole_partially: ltlCountry.whole_partially,
    }
  }
  else if (country && postcode) {
    const ltlCountry = ltlCountries.find(code => code.country === country)

    if (ltlCountry) {
      const disallowedPostcodes = ltlCountry?.regions.find(code => code.allowed === AllowedEnum.NotAllowed)
      const partiallyAllowedPostcodes = ltlCountry?.regions.find(code => code.allowed === AllowedEnum.PartiallyAllowed)

      let notAllowed = false
      let partiallyAllowed = false

      if (disallowedPostcodes?.postcodes)
        notAllowed = !!disallowedPostcodes.postcodes.find(code => postcode.startsWith(code))

      if (partiallyAllowedPostcodes?.postcodes && !notAllowed)
        partiallyAllowed = !!partiallyAllowedPostcodes.postcodes.find(code => postcode.startsWith(code))

      return {
        whole_disallowed: ltlCountry.whole_disallowed,
        whole_partially: ltlCountry.whole_partially,
        notAllowed,
        partiallyAllowed,
      }
    }
  }

  return {}
}

function checkLTL(result: any, currentTotalWeight: number, tailLift: boolean | undefined) {
  if (result.whole_disallowed || result.notAllowed)
    return false
  else if ((result.partiallyAllowed || result.whole_partially) && !tailLift)
    return currentTotalWeight <= 300
  else if (tailLift)
    return currentTotalWeight <= 250
  else
    return currentTotalWeight <= 500
}
