import type { Item, Rectangle } from './types'

// implementations based off of http://pds25.egloos.com/pds/201504/21/98/RectangleBinPack.pdf

const createSplitRectangle = (rectangle: Rectangle) => ({ ...rectangle, splitFrom: rectangle.id })

abstract class Splitter {
  constructor(public kerfSize: number) {}
  abstract split(rectangle: Rectangle, item: Item): Rectangle[]
  protected splitHorizontally(rectangle: Rectangle, item: Item): Rectangle[] {
    const rectangle1 = {
      ...createSplitRectangle(rectangle),
      x: rectangle.x + item.width + this.kerfSize,
      width: rectangle.width - item.width - this.kerfSize,
      height: item.height,
      id: 'sh-r1',
    }

    const rectangle2 = {
      ...createSplitRectangle(rectangle),
      y: rectangle.y + item.height + this.kerfSize,
      height: rectangle.height - item.height - this.kerfSize,
      id: 'sh-r2',
    }

    return [rectangle1, rectangle2]
  }

  protected splitVertically(rectangle: Rectangle, item: Item): Rectangle[] {
    const rectangle1 = {
      ...createSplitRectangle(rectangle),
      y: rectangle.y + item.height + this.kerfSize,
      width: item.width,
      height: rectangle.height - item.height - this.kerfSize,
      id: 'sh-r1',
    }

    const rectangle2 = {
      ...createSplitRectangle(rectangle),
      x: rectangle.x + item.width + this.kerfSize,
      y: rectangle.y,
      width: rectangle.width - item.width - this.kerfSize,
      id: 'sh-r2',
    }

    return [rectangle1, rectangle2]
  }
}

class ShortAxisSplitter extends Splitter {
  split(rectangle: Rectangle, item: Item) {
    if (rectangle.width < rectangle.height)
      return this.splitHorizontally(rectangle, item)
    else
      return this.splitVertically(rectangle, item)
  }
}

class LongAxisSplitter extends Splitter {
  split(rectangle: Rectangle, item: Item) {
    if (rectangle.width > rectangle.height)
      return this.splitHorizontally(rectangle, item)
    else
      return this.splitVertically(rectangle, item)
  }
}

class ShorterLeftoverAxisSplitter extends Splitter {
  split(rectangle: Rectangle, item: Item) {
    if (rectangle.width - item.width < rectangle.height - item.height)
      return this.splitHorizontally(rectangle, item)
    else
      return this.splitVertically(rectangle, item)
  }
}

class LongerLeftoverAxisSplitter extends Splitter {
  split(rectangle: Rectangle, item: Item) {
    if (rectangle.width - item.width >= rectangle.height - item.height)
      return this.splitHorizontally(rectangle, item)
    else
      return this.splitVertically(rectangle, item)
  }
}

export enum SplitStrategy {
  LongLeftoverAxisSplit,
  ShortLeftoverAxisSplit,
  LongAxisSplit,
  ShortAxisSplit,
}

export function GetSplitImplementation(strategy: SplitStrategy, kerfSize: number): Splitter {
  switch (strategy) {
    case SplitStrategy.LongAxisSplit:
      return new LongAxisSplitter(kerfSize)
    case SplitStrategy.ShortAxisSplit:
      return new ShortAxisSplitter(kerfSize)
    case SplitStrategy.LongLeftoverAxisSplit:
      return new LongerLeftoverAxisSplitter(kerfSize)
    case SplitStrategy.ShortLeftoverAxisSplit:
      return new ShorterLeftoverAxisSplitter(kerfSize)
  }
}
