<script lang="ts" setup>
import { useToast } from 'vue-toastification'
import BlogRedirect from '@layouts/components/BlogRedirect.vue'
import FancyUIObject from '@/components/FancyUIObject.vue'
import LiveMessages from '@/views/free-quote/components/LiveMessages.vue'
import FreeQuote from '@/views/free-quote/components/FreeQuote.vue'
import HowItWorksView from '@layouts/components/HowItWorksView.vue'
import ServicesBenefitsView from '@layouts/components/ServicesBenefitsView.vue'
import Header from '@layouts/components/Header.vue'
import Footer from '@layouts/components/Footer.vue'

const highlightTarget = ref(false)

function handleHighlight() {
  highlightTarget.value = true
}

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const toast = useToast()

if (route.query.canceled === 'true') {
  // idk xd
  toast.error(t('The quote has been canceled, please create a new one.'))

  const query = { ...route.query }

  delete query.canceled
  router.replace({ query })
}
</script>

<template>
  <div class="position-relative">
    <div class="dark-bg">
      <div class="container">
        <Header @highlight="handleHighlight" />
      </div>
    </div>

    <div class="bg-image">
      <div class="blue-tint" />
      <div class="container free-quote">
        <FreeQuote :highlight="highlightTarget" @update:highlight="highlightTarget = $event" />
      </div>
    </div>
    <div class="container">
      <LiveMessages />
    </div>
    <div class="container py-5">
      <HowItWorksView />
    </div>
    <div class="dark-bg bg-image-roads py-5">
      <div class="dark-tint" />
      <div class="container py-lg-5 py-1 text-light z-2 position-relative">
        <div class="row justify-content-around">
          <div class="col-lg-auto text-center">
            <h1 class="fw-bold">
              1360
            </h1>
            <p>{{ $t('satisfied clients') }}</p>
          </div>
          <div class="col-lg-auto text-center">
            <h1 class="fw-bold">
              156
            </h1>
            <p>{{ $t('transports on the road right now') }}</p>
          </div>
          <div class="col-lg-auto text-center">
            <h1 class="fw-bold">
              8
            </h1>
            <p>{{ $t('years on the market') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding-top: 8rem">
      <ServicesBenefitsView />
    </div>
    <div class="container" style="padding-top: 2rem; padding-bottom: 8rem;">
      <BlogRedirect />
    </div>
    <FancyUIObject />
  </div>
  <div class="dark-bg">
    <div class="container py-5">
      <Footer />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/colors';

.bg-image {
  position: relative;
  background-image: url('@images/background.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 80%;

  @media (max-width: 767px) {
    height: 95%;
  }
}
.blue-tint{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $primary;
  opacity: 0.5;
  z-index: 1;
}
.free-quote{
  position: relative;
  z-index: 2;
}

.bg-image-roads {
  position: relative;
  background-image: url('@images/background_roads.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
.dark-tint{
  position: absolute;
  background-color: $secondary;
  opacity: 0.9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
</style>

<route lang="yaml">
meta:
  layout: blank
</route>
