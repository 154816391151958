import { acceptHMRUpdate, defineStore } from 'pinia'
import type { PostalCodes } from '@apiTypes'
import api from '@api'

export const usePostalCodeStore = defineStore('postal-codes', {
  state: () => ({
    postal_codes: {} as PostalCodes,
    lastFetched: 0 as number,
    fetchPromise: null as Promise<PostalCodes> | null,
  }),
  actions: {
    async getSpecialPostalCodes(): Promise<PostalCodes> {
      const currentTime = Date.now()
      const refreshInterval = 300000

      if (this.fetchPromise)
        return this.fetchPromise

      const shouldFetch = !this.lastFetched || (currentTime - this.lastFetched) > refreshInterval

      if (shouldFetch) {
        this.fetchPromise = api.postalCodesRetrieve()
          .then(response => {
            this.$patch({
              postal_codes: response.data,
              lastFetched: currentTime,
            })

            return this.postal_codes
          })
          .catch(error => {
            console.error('Failed to fetch postal codes:', error)
            throw error
          })
          .finally(() => {
            this.fetchPromise = null
          })

        return this.fetchPromise
      }

      return Promise.resolve(this.postal_codes)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePostalCodeStore, import.meta.hot))
