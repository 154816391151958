import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Utm } from '@apiTypes'

export const useUtmStore = defineStore('utm', {
  state: () => ({
    utm: {} as Utm,
  }),
  actions: {
    storeUtmParameters(query: Partial<Utm>) {
      if (query.utm_source)
        this.utm = query
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUtmStore, import.meta.hot))
