import type { AxiosRequestConfig } from 'axios'
import { useAuthStore } from '@store/auth'
import api, { axiosInstance } from '@api'
import type { JWT, Login, Register, TokenRefresh, UserDetails } from '@apiTypes'
import router from '@/router'
import storage from '@/utils/storage'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
const accessTokenKey = 'accessToken'
const refreshTokenKey = 'refreshToken'
const accessToken = () => storage.getItem(accessTokenKey)
const refreshToken = () => storage.getItem(refreshTokenKey)
export const useAccessToken = useStorage<string>(accessTokenKey, null, storage)
useAccessToken.value = accessToken()
export const useRefreshToken = useStorage<string>(refreshTokenKey, null, storage)
useRefreshToken.value = refreshToken()
export const isUserLoggedIn = () => !!useAccessToken.value
export const hasRefreshToken = () => !!useRefreshToken.value

export function loginHelper(data: Login): Promise<UserDetails> {
  return new Promise((resolve, reject) => {
    api.login.authLoginCreate(data, { skipAuthToken: true })
      .then(r => {
        handleJWTResponse(r.data)
        resolve(r.data.user)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function refreshTokenHelper(): Promise<TokenRefresh> {
  const data: TokenRefresh = {
    refresh: useRefreshToken.value || '',
    access: useAccessToken.value || '',
  }

  console.log('[AXIOS] Response Interceptors - Refreshing token')

  return new Promise((resolve, reject) => {
    api.token.authTokenRefreshCreate(data, { skipAuthRefresh: true })
      .then(r => {
        handleTokens(r.data)
        resolve(r.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function registerHelper(data: Register): Promise<UserDetails> {
  return new Promise((resolve, reject) => {
    api.register.authRegisterCreate(data, { skipAuthToken: true })
      .then(r => {
        handleJWTResponse(r.data)
        resolve(r.data.user)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function handleJWTResponse(data: JWT) {
  const { access, refresh, user } = data

  const authStore = useAuthStore()

  authStore.setCurrentUser(user)
  handleTokens({ access, refresh })
}

function handleTokens(data: { refresh?: string; access?: string }) {
  if (data.access)
    useAccessToken.value = data.access

  if (data.refresh)
    useRefreshToken.value = data.refresh
}

export function logoutHelper() {
  axiosInstance.post('/api/auth/logout/', { refresh: refreshToken() }, { skipAuthToken: true, skipLogout: true })

  clearAuthDataAndRedirect()
}

export function clearAuthDataAndRedirect() {
  clearAuthData()
  router.push({ name: 'login' })
}

export function clearAuthData() {
  useAccessToken.value = null
  useRefreshToken.value = null

  const authStore = useAuthStore()

  authStore.clear()
}

export function setHeaders(config: AxiosRequestConfig) {
  const authStore = useAuthStore()

  config.headers = config.headers || {}

  config.headers['Accept-Language'] = authStore.getCurrentLanguage || 'en'

  const token = useAccessToken.value

  if (!token)
    return // Exit early if there's no token

  // Type assertion to indicate that headers are guaranteed to be defined
  const headers = config.headers!

  if (!config.skipAuthToken) {
    // ** eslint-disable-next-line no-param-reassign
    headers.Authorization = `Bearer ${token}`
  }
}
