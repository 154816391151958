<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: 'btn-primary',
  },
})

const emit = defineEmits<{
  (e: 'submit'): void
}>()
</script>

<template>
  <button
    type="button" class="btn"
    :class="buttonClass" :disabled="loading || disabled"
    @click="emit('submit')"
  >
    <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" role="status" />
    <span v-else><i v-if="icon" :class="icon" class="me-2" /></span>
    <span>{{ title }}</span>
  </button>
</template>
