<script setup lang="ts">
import { useWindowWidth } from '@/utils'
import data from '@/utils/5_second.json'
import orderIcon from '@images/ic_order.svg'
import loadingIcon from '@images/ic_loading.svg'
import unloadingIcon from '@images/ic_unloading.svg'

interface DataItem {
  message: string
  type: 'loading' | 'unloading' | 'order'
}

const windowWidth = useWindowWidth()

const { randomIndex: index, updateRandomIndex: updateIndex } = useRandomIndex()
const { randomIndex: index2, updateRandomIndex: updateIndex2 } = useRandomIndex()
const { randomIndex: index3, updateRandomIndex: updateIndex3 } = useRandomIndex()

const visibleTexts = computed(() => {
  const dataList: DataItem[] = []
  if (windowWidth.value <= 768) {
    dataList.push(data[index.value % data.length] as DataItem)
  }
  else {
    dataList.push(
      data[index.value % data.length] as DataItem,
      data[index2.value % data.length] as DataItem,
      data[index3.value % data.length] as DataItem,
    )
  }

  return dataList
})

function useRandomIndex(): { randomIndex: Ref<number>; updateRandomIndex: () => void } {
  const randomIndex = ref(Math.floor(Math.random() * data.length))

  const updateRandomIndex = () => {
    randomIndex.value = Math.floor(Math.random() * data.length)
  }

  return { randomIndex, updateRandomIndex }
}

setInterval(() => {
  if (windowWidth.value <= 768) {
    updateIndex()
  }
  else {
    updateIndex()
    updateIndex2()
    updateIndex3()
  }
}, 9000)
</script>

<template>
  <div class="row">
    <TransitionGroup name="slide" tag="div" class="row d-flex justify-content-center">
      <div v-for="text in visibleTexts" :key="text" class="col">
        <div class="card shadow-lg bubble" style="max-width: 700px; height: 75px">
          <div class="card-body text-center d-flex align-items-center justify-content-center">
            <span class="circle">
              <img v-if="text.type === 'order'" :src="orderIcon" alt="order icon">
              <img v-if="text.type === 'loading'" :src="loadingIcon" alt="loading icon">
              <img v-if="text.type === 'unloading'" :src="unloadingIcon" alt="unloading icon">
            </span>

            {{ text.message }}
          </div>
        </div>
      </div>
    </Transitiongroup>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/colors';
.row{
  position: relative;
  z-index: 2;
}

.bubble{
    top: -50%;
    transform: translateX(3%);
    z-index: 2;
    border-radius: 18px;
}

.slide-leave-from{
  transform: translateY(60%);
  opacity: 1;
}

.slide-leave-to{
  transform: translate(-50%, -60%);
  opacity: 0;
}

.slide-enter-from{
  transform: translateX(200%);
  opacity: 0;
}

.circle {
  background-color: $light-gray;
  border-radius: 50%;
  padding: 5px;
}

.slide-enter-active, .slide-move, .slide-leave-active{
  transition: all 2s ease;
}
.slide-leave-active{
  position: absolute;
}
</style>
